import { useState, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../features/data/fileDataSlice';

// React Router Dom
import { useNavigate } from 'react-router-dom';

// React Toastify
import { toast } from 'react-toastify';

// Constants
import { baseRequestUrlV2 } from '../constants/Default';

type StatsData = {
  scheduledFilesCount: number | null;
  uploadedFilesCount: number | null;
  notUploadedFilesCount: number | null;
};

type FetchVideoStatsResult = {
  statsData: StatsData | null;
};

type FetchVideoStatsHook = (
  from: string | null,
  to: string | null,
  center: string[] | null
) => FetchVideoStatsResult;

const useFetchVideoStats: FetchVideoStatsHook = (from, to, center) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [statsData, setStatsData] = useState<StatsData | null>(null);

  // console.log(center, room, from, to, fileType);

  useEffect(() => {
    const getStatsData = async (
      from: string | null,
      to: string | null,
      center: string[] | null
    ) => {
      // Creating the query string
      let queryString: string = '';

      // // Adding the from and to time to the query string
      if (from !== null && to !== null) {
        // Extracting the Date and Time from the string
        queryString += `from=${from}&to=${to}`;
      }

      // Adding the center and room to the query string
      if (center?.length !== 0) {
        queryString += `&centers=${JSON.stringify(center)}`;
      }

      // Final query string
      let finalQueryString: string;

      finalQueryString = `${baseRequestUrlV2}/video/stats?${queryString}`;

      try {
        dispatch(setIsLoading(true));

        const response = await fetch(finalQueryString, {
          method: 'GET',
          headers: {
            Authorization: localStorage.getItem('id_token') as string,
            // secret: 'Y9ecx0MVB4w&sW6PVtBb5df!2Q8#DRfPTSvVg%DQ!q1oq*FQXs',
          },
        });
        const data = await response.json();
        setStatsData(data.data);
      } catch (error) {
        toast.error('Error in fetching data');
        localStorage.clear();
        navigate('/login');
      }
      // Getting all the response in the [{}] format
    };

    getStatsData(from, to, center);
  }, [from, to, center, dispatch, navigate]);

  return { statsData, setStatsData };
};

export default useFetchVideoStats;
