import { useState, useRef, Fragment } from 'react';
import { CSVLink } from 'react-csv';

import moment from 'moment';

import { LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import styles from './CommonStyling.module.css';
import { useNavigate } from 'react-router-dom';
import type { RootState } from '../../../app/store';
import { useSelector } from 'react-redux';
import { csvHeaders } from '../../../constants/CsvHeaders';
import { toast } from 'react-toastify';
import { baseRequestUrlV2 } from '../../../constants/Default';

const DownloadCSV = () => {
  const { center, room, from, to, fileType, batchId, uploaded, scheduled } = useSelector(
    (state: RootState) => state.filter
  );

  const [isDataDownloading, { open, close }] = useDisclosure(false);

  const navigate = useNavigate();
  const [csvData, setCsvData] = useState<any[]>();
  const csvInstance = useRef<any | null>(null);
  // console.log('File Data : - Download CSV.tsx ', state);

  const asyncExportMethod = async (
    center: string[] | null,
    room: string | null,
    from: string | null,
    to: string | null,
    fileType: string | null,
    uploaded: number,
    scheduled: number
  ) => {
    open();
    // Creating the query string
    let queryString: string = '';

    // // Adding the from and to time to the query string
    if (from !== null && to !== null) {
      // Extracting the Date and Time from the string
      queryString += `from=${from}&to=${to}`;
    }

    // Adding the center and room to the query string
    if (center?.length !== 0) {
      queryString += `&centers=${JSON.stringify(center)}`;
    }

    if (room) {
      queryString += `&room=${room}`;
    }

    // Adding the file type to the query string
    if (fileType) {
      queryString += `&type=${fileType}`;
    }

    if (uploaded!==0){
      queryString += `&uploaded=${uploaded}`
    } 

    if (scheduled!==0){
      queryString += `&scheduled=${scheduled}`
    } 

    // Adding the batch id to the query string
    if (batchId) {
      queryString += `&batchId=${batchId}`;
    }

    // Final query string
    let finalQueryString: string;

    finalQueryString = `${baseRequestUrlV2}/all?${queryString}`;

    try {
      // dispatch(setIsLoading(true));

      const response = await fetch(finalQueryString, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('id_token') as string,
          // secret: 'Y9ecx0MVB4w&sW6PVtBb5df!2Q8#DRfPTSvVg%DQ!q1oq*FQXs',
        },
      });
      const data = await response.json();

      close();

      // console.log('Download data: ', data);

      const newData = data.data.map((item) => {
        return {
          ...item,
          startTime: moment(item.startTime).format('MMMM Do YYYY, h:mm:ss a'),
          stopTime: moment(item.stopTime).format('MMMM Do YYYY, h:mm:ss a'),
          scheduleId: item?.scheduleDetails?._id,
          batchName: item?.scheduleDetails?.batchNameProp,
          batchId: item?.scheduleDetails?.batchIdProp,
        };
      });

      setCsvData(newData);

      setTimeout(() => {
        csvInstance.current.link.click();
      }, 1000);
    } catch (error) {
      toast.error('Error in fetching data');
      localStorage.clear();
      navigate('/login');
    }
    // Getting all the response in the [{}] format
  };

  return (
    <Fragment>
      <LoadingOverlay visible={isDataDownloading} overlayBlur={2} />
      <button
        onClick={() => asyncExportMethod(center, room, from, to, fileType, uploaded, scheduled)}
        className={styles['csv']}
      >
        {/* Download CSV */}
        CSV
      </button>
      {csvData ? (
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={'data.csv'}
          ref={csvInstance}
        />
      ) : undefined}
    </Fragment>
  );
};

export default DownloadCSV;
