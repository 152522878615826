import React from "react";

import Actions from ".././action/Actions";
import CenterRoom from ".././center/CenterRoom";
import Date from ".././date/Date";
import FileName from ".././file/FileName";
import FileType from ".././file/FileType";
import Status from ".././status/Status";
import LateBy from ".././status/LateBy";
import ThumbNail from "../thumbnail/ThumbNail";
import fileData from "../../../interface/props/table/files";
import "./Card.css";
import { Tooltip } from "@mantine/core";

type Props = {
  document: fileData;
};

const Card: React.FC<Props> = ({ document }) => {
  // console.log('Document: ', document);
  return (
    <>
      <div
        style={{
          backgroundColor: "#cccccc30",
          width: "350px",
          height: "100px",
          padding: "1rem",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          justifyContent: "center",
          // textAlign: "center",
          position: "relative", // Add relative positioning
        }}
        className="card"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <CenterRoom document={document} />
            <span
              style={{
                fontSize: "0.8rem",
                color: "#00000095",
              }}
            >
              Room-{document?.room}
            </span>
            <Date document={document} />
            {/* <FileType document={document} /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: "1rem",
                alignItems: "center",
                marginTop: "0.3rem",
              }}
            >
              <Status document={document} />
              {/* <LateBy document={document} /> */}
              <div style={{
                display: "flex",
                gap: "0.2rem",
              }}>
                <Actions document={document} />
                <FileName document={document} />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: "90px",
            }}
          >
            <ThumbNail document={document} />
          </div>
        </div>
        {/* <div
          style={{
            position: "absolute",
            top: "10px",
            right: "7px",
            // backgroundColor: "#ccc",
            // padding: "1px",
            // padding: "5px",
            borderRadius: "5px",
            // boxShadow: "0 0 5px rgba(0, 0, 0, 0.05)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <FileType document={document} />
            <FileName document={document} />
          </div>
        </div> */}
      </div> 
    </>
  );
};

export default Card;
