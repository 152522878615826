import React from 'react';
import moment from 'moment';

import fileData from '../../../interface/props/table/files';
import parseDateTime from '../../../utils/getDateAndTime';

type Props = {
  document: fileData;
};

const Date: React.FC<Props> = ({ document }) => {
  
  return (
    <div style={{ 
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      fontSize: '0.8rem',
      color: '#00000095',
    }}>
      {/* {parseDateTime(document.startTime).date + " " + parseDateTime(document.startTime).time} */}
      {/* type 4th may 2024 */}
      {moment(document.startTime).format('Do MMMM HH:mm')}
    </div>
  );
};

export default Date;
