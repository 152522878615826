type Props = {
  status: boolean;
  text?: string;
};

const Dot: React.FC<Props> = (props) => {
  const { status, text } = props;
  const color = status ? '#12b76a' : '#dc3c3d';
  return (
    <div
      style={{
        width: '1.1rem',
        height: '1.1rem',
        borderRadius: '50%',
        backgroundColor: `${color}`,
        alignSelf: 'center',
      }}
    >
      <p style={{
        fontSize: '0.8rem',
        fontWeight: 'bolder',
        color: '#ffffff',
        textAlign: 'center',
        margin: '0',
        lineHeight: '1.2rem',
      }}>{text}</p>
    </div>
  );
};

export default Dot;
