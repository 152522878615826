import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type User = {
  user : {
    view : "personalView" | "generalView";
  }
};
const initialState: User = {
  user : {
    view : "generalView"
  }
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserView : (state, action: PayloadAction<"personalView" | "generalView">) => {
        state.user.view = action.payload;
    }
  },
});

export const { setUserView } = userSlice.actions;

export default userSlice.reducer;
