const defaultImgUrl = 'https://mui.com/static/images/avatar/2.jpg';

// For development
// const baseRequestUrl = 'http://localhost:3100/v1/studio/files';

// For Production
const baseRequestUrl = 'https://studio-app-api.penpencil.co/v1/studio/files';
const baseRequestUrlV2 = 'https://studio-app-api.penpencil.co/v2/studio/files';

export { defaultImgUrl, baseRequestUrl, baseRequestUrlV2 };
