import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../app/store';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { setIsLoading } from '../../features/data/fileDataSlice';
import { baseRequestUrl, baseRequestUrlV2 } from '../../constants/Default';

import { setFileData } from '../../features/data/fileDataSlice';

import styles from '../filters/buttons/CommonStyling.module.css';
import fileDataType from '../../interface/data/FileData';

const LoadMore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { center, room, from, to, fileType, batchId, uploaded, scheduled } = useSelector(
    (state: RootState) => state.filter
  );

  const { data: fileData } = useSelector((state: RootState) => state.fileData);

  const handleLoadMoreClick = async () => {
    // console.log('LoadMore clicked');

    // Creating the query string
    let queryString: string = '';

    // // Adding the from and to time to the query string
    if (from !== null && to !== null) {
      // Extracting the Date and Time from the string
      queryString += `from=${from}&to=${to}`;
    }

    // Adding the center and room to the query string
    if (center?.length !== 0) {
      queryString += `&centers=${JSON.stringify(center)}`;
    }

    if (room) {
      queryString += `&room=${room}`;
    }

    if (uploaded!==0){
      queryString += `&uploaded=${uploaded}`
    } 

    if (scheduled!==0){
      queryString += `&scheduled=${scheduled}`
    } 

    // Adding the file type to the query string
    if (fileType) {
      queryString += `&type=${fileType}`;
    }

    // Adding the batch id to the query string
    if (batchId) {
      queryString += `&batchId=${batchId}`;
    }

    // Final query string
    let finalQueryString: string;

    // @ts-ignore
    finalQueryString = `${baseRequestUrlV2}?${queryString}&skip=${fileData?.data?.length}&limit=100`;

    try {
      dispatch(setIsLoading(false));

      const response = await fetch(finalQueryString, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('id_token') as string,
        },
      });
      const data = await response.json();

      let newData: fileDataType[] = [];

      // @ts-ignore
      // fileData?.data?.forEach((file: fileDataType) => {
      //   newData.push(file);
      //   console.count('pushed')
      // });

      console.log(fileData, "filedata---")
      console.log(data, "data---")

      // @ts-ignore
      newData = {data: [...fileData?.data, ...data.data.data]};
      // console.log('New file Data: ', newData);
      dispatch(setFileData(newData));
    } catch (error) {
      toast.error('Error in fetching data');
      // console.log('Error in fetching data: ', error);
      localStorage.clear();
      navigate('/login');
    }
  };

  return (
    <button
      style={{
        display: fileData.length === 0 ? 'none' : 'flex',
        justifyContent: 'center',
      }}
      className={styles['common-styling']}
      onClick={handleLoadMoreClick}
    >
      Load More
    </button>
  );
};

export default LoadMore;
