import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface FilterState {
  isReset: boolean;
  from: string | null;
  to: string | null;
  center: string[];
  room: string;
  fileType: string;
  batchId: string | null;
  uploaded: number;
  scheduled: number;
  youtubeId: string;
}
// 7 days before
const From = new Date();
From.setDate(From.getDate() - 6);
From.setHours(0, 0, 0, 0);
const from = From.toISOString();

const To = new Date();
To.setHours(23, 59, 0, 0);
const to = To.toISOString();

const initialState: FilterState = {
  isReset: false,
  from: from,
  to: to,
  center: [],
  room: '',
  fileType: '',
  batchId: null,
  uploaded: 0,
  scheduled: 0,
  youtubeId: '',
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFrom: (state, action: PayloadAction<string | null>) => {
      // console.log('setFrom', action.payload);
      state.from = action.payload;
    },
    setTo: (state, action: PayloadAction<string | null>) => {
      // console.log('setTo', action.payload);
      state.to = action.payload;
    },
    setCenter: (state, action: PayloadAction<string[]>) => {
      // console.log('setCenter', action.payload);
      state.center = action.payload;
      state.room = '';
      state.batchId = null;
    },
    setRoom: (state, action: PayloadAction<string>) => {
      // console.log('setRoom', action.payload);
      state.room = action.payload;
    },
    setFileType: (state, action: PayloadAction<string>) => {
      // console.log('setFileType', action.payload);
      state.fileType = action.payload;
    },

    setBatchId: (state, action: PayloadAction<string | null>) => {
      state.batchId = action.payload;
    },

    setYoutubeId: (state, action: PayloadAction<string>) => {
      state.youtubeId = action.payload;
    },

    setUploaded: (state, action: PayloadAction<number>) => {
      state.uploaded = action.payload;
    },

    setScheduled: (state, action: PayloadAction<number>) => {
      state.scheduled = action.payload;
    },

    handleReset: (state, action: PayloadAction<boolean>) => {
      state.isReset = action.payload;

      if (action.payload) {
        state.from = from;
        state.to = to;
        state.center = [];
        state.room = '';
        state.fileType = '';
        state.batchId = null;
        state.uploaded = 0;
        state.scheduled = 0;
        state.youtubeId = '';
      }
    },
  },
});

export const {
  setFrom,
  setTo,
  setCenter,
  setRoom,
  setFileType,
  setBatchId,
  handleReset,
  setUploaded,
  setScheduled,
  setYoutubeId,
} = filterSlice.actions;

export default filterSlice.reducer;
