import { useState, useEffect, useMemo } from 'react';
import { DatePickerInput } from '@mantine/dates';

import type { RootState } from '../../../app/store';
import { useSelector, useDispatch } from 'react-redux';

import {
  setFrom,
  setTo,
  handleReset,
} from '../../../features/filters/filterSlice';

const From = new Date();
From.setDate(From.getDate() - 6);
From.setHours(0, 0, 0, 0);

const To = new Date();
To.setHours(23, 59, 0, 0);

const DateRange = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.filter.isReset);
  // const filterState = useSelector((state: RootState) => state.filter);

  // console.log('Filter State : ', filterState);

  const isReset = useMemo(() => {
    return state;
  }, [state]);

  const [value, setValue] = useState<[Date | null, Date | null]>([From, To]);
  const [date, setDate] = useState(new Date());

  const handleChange = (val: [Date | null, Date | null]) => {
    if (val[0] !== null && val[1] === null) {
      setDate((current) => new Date(current.getFullYear(), current.getMonth()));
    }

    setValue(val);
    dispatch(handleReset(false));
    dispatch(setFrom(val[0]?.toISOString() || null));
    dispatch(setTo(val[1]?.toISOString() || null));
  };

  useEffect(() => {
    if (isReset) {
      setValue([From, To]);
    }
  }, [isReset]);

  return (
    <DatePickerInput
      date={date}
      onDateChange={setDate}
      valueFormat='DD MMM YYYY'
      label='Pick date range'
      placeholder='Pick date range'
      value={value}
      onChange={handleChange}
      type='range'
      // clearable
      miw={250}
      maw={370}
    />
  );
};

export default DateRange;
