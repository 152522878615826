import { useState, useEffect, useMemo, forwardRef } from 'react';
import { MultiSelect } from '@mantine/core';

import type { RootState } from '../../../app/store';
import type { SelectItemProps } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import { setCenter, handleReset } from '../../../features/filters/filterSlice';

type Props = {
  data: {
    label: string;
    value: string;
  }[];
};

const MultipleOption: React.FC<Props> = ({ data }) => {
  const state = useSelector((state: RootState) => state.filter.isReset);

  const isReset = useMemo(() => {
    return state;
  }, [state]);
  const dispatch = useDispatch();

  const [value, setValue] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleValueChange = (value: string[]) => {
    setValue(value);
    dispatch(handleReset(false));
    dispatch(setCenter(value));
  };

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    dispatch(handleReset(false));
  };

  // console.log('Value from MultipleOption.tsx : ', value);

  useEffect(() => {
    if (isReset) {
      setValue([]);
      setSearchValue('');
    }
  }, [isReset]);

  // console.log('All Selected centers : ', value);

  const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
    ({ label, ...others }: SelectItemProps, ref) => (
      <div ref={ref} {...others}>
        <div>{label}</div>
      </div>
    )
  );

  return (
    <MultiSelect
      data={data ? data : [{ label: 'hello', value: 'hello', key: 'hello' }]}
      value={value}
      itemComponent={SelectItem}
      searchValue={searchValue}
      onChange={handleValueChange}
      onSearchChange={handleSearchValueChange}
      label='Centers'
      searchable
      nothingFound='No centers found with the search query'
      placeholder='Select centers'
      clearable
      maxDropdownHeight={160}
      transitionProps={{
        duration: 150,
        transition: 'pop-top-left',
        timingFunction: 'ease',
      }}  
      filter={(value, selected, item) =>
        !selected &&
        (item?.label?.toLowerCase().includes(value?.toLowerCase().trim()) ||
          false)
      }
      w={250}
      maw={250} 
    />
  );
};

export default MultipleOption;
