import React from 'react';

import styles from './CommonStyling.module.css';

import { useDispatch } from 'react-redux';
import { handleReset } from '../../../features/filters/filterSlice';
import { setUserView } from '../../../features/data/userSlice';

const Reset = () => {
  const dispatch = useDispatch();

  return (
    <button
      className={styles['common-styling']}
      onClick={() => {
        dispatch(handleReset(true))
        dispatch(setUserView("generalView"))
      }}
    >
      Reset
    </button>
  );
};

export default Reset;
