import React from "react";

import fileData from "../../../interface/props/table/files";
import Schedule from "../../modal/Schedule/Schedule";

import styles from "./ThumbNail.module.css";
import { useDisclosure } from "@mantine/hooks";
import PreviewModal from "../../modal/Preview/PreviewModal";

type Props = {
  document: fileData;
};

const ThumbNail: React.FC<Props> = ({ document }) => {
  const youtubeId = document.youtubeId;
  const [
    isPreviewModalOpen,
    { open: openPreviewModal, close: closePreviewModal },
  ] = useDisclosure(false);

  return (
    <div className={styles["thumbnail-container"]}>
      {youtubeId ? (
        <img
          onClick={() => {
            openPreviewModal();
          }}
          alt={document.fileName}
          src={`https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        />
      ) : (
        <div className={styles["thumbnail-container-schedule"]}>
          {document.schedules ? (
            document.fileType === "notes" && document.schedules?.length > 0 ? (
              <div className={styles["thumbNailText"]}>Notes scheduled</div>
            ) : (
              <div className={styles["thumbNailText"]}>
                Found {document.schedules?.length} schedules
              </div>
            )
          ) : (
            // <div>Found {document.schedules?.length} schedules</div>
            <div>Unable to publish the video </div>
          )}
          {/* {document.schedules?.length !== 0 && !document.scheduled && (
            <Schedule document={document} title='Select' />
          )}
          {document.schedules?.length === 0 && !document.scheduled && (
            <Schedule document={document} title='Find' />
          )} */}
        </div>
      )}

      {isPreviewModalOpen && (
        <PreviewModal
          title="Preview Video"
          opened={isPreviewModalOpen}
          open={openPreviewModal}
          close={closePreviewModal}
          document={document}
        />
      )}
    </div>
  );
};

export default ThumbNail;
