import React from 'react';
import Swal from 'sweetalert2'
import fileData from '../../../interface/props/table/files';
import {FaCopy} from 'react-icons/fa6'

type Props = {
  document: fileData;
};

const FileName: React.FC<Props> = ({ document }) => {

  

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        width: '100%',
        gap: '5px',
        alignSelf: 'center',
      }}
    >
      {/* <div style={{
        textOverflow: 'ellipsis'
      }}>
        {document.fileName} 
      </div> */}
      <button 
        style={{
          // padding: '2px 5px 2px 2px',
          border: 'none',
          outline: 'none',
          background: 'transparent',
          // color: 'white',
          cursor: 'pointer',
          // fontSize: '12px',
          // fontWeight: 'bold',
          borderRadius: '5px'
          // fontFamily: 'inherit',
        }}
        onClick={() => {
          navigator.clipboard.writeText(document._id)
          Swal.fire({
            icon: 'success',
            title: `ID Copied: ${document._id}`,
            showConfirmButton: false,
            timer: 1500
          })
        }}
      >
        <FaCopy size={16} color='#5a4bda'/>
      </button>
    </div>
  );
};

export default FileName;
