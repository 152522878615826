import { useEffect, useState } from 'react';

// Components
import Header from './header/Header';
import Row from './row/Row';
import Card from './card/Card';

// Mantine
import { Loader } from '@mantine/core';

// Styles
import styles from './DataTable.module.css';

// Redux
import type { RootState } from '../../app/store';
import { useSelector, useDispatch } from 'react-redux';
import { setFileData, setIsLoading } from '../../features/data/fileDataSlice';

// Hooks
import useFetchFileData from '../../hooks/useFetchAllData';

// Types
import fileDataType from '../../interface/data/FileData';

// Utils
import removeDuplicates from '../../utils/removeDuplicates';

// Lottie
import Lottie from 'react-lottie';
import animationData from '../../assets/no-data.json';

import { Modal } from '@mantine/core';
import DataModal from './modal/DataModal';
import axios from 'axios';
import { setCenter, setRoom }from '../../features/filters/filterSlice';

const DataTable = () => {
  const [newDataFinal, setNewDataFinal] = useState<fileDataType[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<fileDataType | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const filterState = useSelector((state: RootState) => state.filter);

  const { center, room, from, to, fileType, batchId, uploaded, scheduled } = filterState;

  console.log("Filter's State: ", filterState);

  // To get the data from the store
  const { data, isLoading } = useSelector((state: RootState) => state.fileData);

  const { data: fileDataGlobalState } = useSelector((state: RootState) => state.fileData);

  // To fetch the data from the backend
  const { fileData } = useFetchFileData(
    center,
    room,
    from,
    to,
    fileType,
    batchId,
    uploaded,
    scheduled
  );

  // Options for the Lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // To set the data in the store
  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(setFileData(fileData || []));
    dispatch(setIsLoading(false));
  }, [fileData, dispatch]);

  return isLoading ? (
    <div>
      <Loader className={styles['loader']} variant='dots' size='md' />
    </div>
  ) : fileDataGlobalState?.length !== 0 ? (
    <div style={{ 
        display: 'flex', 
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '1rem',
      }}>
      {/* @ts-ignore */}
      {fileDataGlobalState?.data?.map((document: fileDataType, index: number) => (
        <div onClick={() => {
          // setSelectedDocument(document);
          // setModalVisible(true);
        }}>
          <Card key={index} document={document} />
        </div>
      ))}
      <Modal
        title={selectedDocument?.room || 'Document Details'}
        opened={modalVisible}
        onClose={() => setModalVisible(false)}
      >
        {/* Render details of selected document inside the modal */}
        {selectedDocument && (
          <>
            <DataModal document={selectedDocument} />
          </>
        )}
      </Modal>
    </div>

  ) : (
    <Lottie options={defaultOptions} width={'100%'} zIndex={0} />
  );
};

export default DataTable;