import React from 'react';

import fileData from '../../../interface/props/table/files';
import Dot from './Dot';

import styles from './Status.module.css';

type Props = {
  document: fileData;
};

const Status: React.FC<Props> = ({ document }) => {
  return (
    <div className={styles['status-container']}>
      <div style={{
        display: 'flex',
        gap: '0.2rem'
      }}>
        {/* U: */}
        <Dot status={document.uploaded} text='U' />
      </div>
      <div style={{
        display: 'flex',
        gap: '0.4rem'
      }}>
        {/* S: */}
        <Dot status={document.scheduled} text='S' />
      </div>
    </div>
  );
};

export default Status;
