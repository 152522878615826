import { useState, useEffect } from 'react';

import { CenterDataSheets, CenterData } from '../interface/data/CenterData';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

const useFetchCentersData = (view : string) => {
  const navigate = useNavigate();

  const pw_master_sheet =
    'https://sheets.googleapis.com/v4/spreadsheets/1OTnzm9gKVce4edD4qWZzeemvmjQknZbon5rLbc8xtAE/values/Master!A:D?key=AIzaSyAaq4k_2Bb_E9xTaJdEeQ9RmEvEu_1AxHQ';
  const dummy_master_sheet =
    'https://sheets.googleapis.com/v4/spreadsheets/1ZkcIR04P7gQu0aBziwDBMfkkQFh94ERry60uvGJD1Ds/values/master!A:D?key=AIzaSyBxt0KCuDepK1R2b4SKj68nDFepMoSjVko';

  const bm_mapping_sheet =
    'https://sheets.googleapis.com/v4/spreadsheets/18UQ1hKjMOCLYb1bueqnB5mAK9RC7e49_aUMhzoHP5Vw/values/bm_mapping!A:F?key=AIzaSyBxt0KCuDepK1R2b4SKj68nDFepMoSjVko';

  const requestUrl = view === 'personalView'? bm_mapping_sheet : dummy_master_sheet;

  const [centersData, setCentersData] = useState<CenterData[] | null>(null);

  useEffect(() => {
    const getCentersData = async () => {
      try {
        const response = await fetch(requestUrl, {
          method: 'GET',
        });

        const data: CenterDataSheets = await response.json();

        const sheetData = data.values;

        // index of Center Name
        // index of Room Name
        // index of Batch Name
        // index of Batch ID
        // index of BM Email

        const centerIndex = sheetData?.[0].indexOf('Center Name');
        const roomIndex = sheetData?.[0].indexOf('Room');
        const batchNameIndex = sheetData?.[0].indexOf('Batch Name');
        const batchIdIndex = sheetData?.[0].indexOf('Batch ID');
        const bmEmailIndex = sheetData?.[0].indexOf('BM Email');
        // Removing the headers from the sheet data
        sheetData?.shift();

        const outputData: CenterData[] = [];

        
        // Now we have to club the data of the same center in the [{center: string, rooms: []}]
        sheetData?.forEach((centerData) => {
          if(view === 'personalView' && centerData[bmEmailIndex] !== localStorage.getItem('email')) return;
          const center = centerData[centerIndex];
          const room = centerData[roomIndex];
          const batchName = centerData[batchNameIndex];
          const batchId = centerData[batchIdIndex];

          // Now we have to check whether the center exsists in the outputData or not
          const exsistingCenter = outputData.find(
            (data) => data.center === center
          );

          if (exsistingCenter) {
            // Add room to exsisting center rooms
            if (!exsistingCenter.rooms.includes(room)) {
              exsistingCenter.rooms.push(room);
            }
            exsistingCenter.batches.push({ batchId, batchName });
          } else {
            // Add a new center to the outputData
            outputData.push({
              center,
              rooms: [room],
              batches: [{ batchId, batchName }],
            });
          }
        });

        // console.log('All center room batch data: ', outputData);

        setCentersData(outputData);
      } catch (error) {
        toast.error('Error while fetching the centers data');
        localStorage.clear();
        navigate('/login');
      }
    };

    getCentersData();
  }, [navigate, requestUrl]);

  return { centersData, setCentersData };
};

export default useFetchCentersData;
