import { configureStore } from '@reduxjs/toolkit';
import filterSlice from '../features/filters/filterSlice';
import fileDataSlice from '../features/data/fileDataSlice';
import facultyOptionSlice from '../features/data/facultyOptionSlice';
import pingSlice from '../features/data/pingSlice';
import userSlice from '../features/data/userSlice';

// The store which contains - state, reducers, actions, etc.
export const store = configureStore({
  reducer: {
    filter: filterSlice,
    fileData: fileDataSlice,
    faculty: facultyOptionSlice,
    pingSlice: pingSlice,
    user : userSlice
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
