import { useEffect } from 'react';

// Components
import OneOption from './oneOption/OneOption';
import MultipleOption from './multipleOption/MultipleOption';
import Date from './dates/Date';
import Reset from './buttons/Reset';
import DownloadCSV from './buttons/DownloadCSV';
import OnlineClients from './online-clients/OnlineClients';
import BatchIdFilter from './batchId/BatchIdFilter';

// Redux
import type { RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { fileTypeData } from '../../constants/Filter';

// Styles
import styles from './Filters.module.css';

// Utils
import { FormattedFacultyDataSelect } from '../../interface/data/FacultyData';

// Hooks
import useFetchCentersData from '../../hooks/useFetchCentersData';
import useFetchAllFaculty from '../../hooks/useFetchAllFaculty';

import {
  setFacultyData,
  setFacultyIdNameData,
  setFacultyOption,
} from '../../features/data/facultyOptionSlice';
import YoutubeIdFilter from './youtubeId/YoutubeIdFilter';

type idNameFacultyDataType = {
  driveId: string;
  name: string;
};

type CenterwiseRoomData = {
  group: string;
  label: string;
  value: string;
  key: string;
};

const Filters = () => {
  const { center } = useSelector((state: RootState) => state.filter);
  const { allFacultyData } = useFetchAllFaculty();
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.user);
  // console.log('All Faculty Data: ', allFacultyData);

  // Push the formatted data into the formattedAllFacultyData array
  useEffect(() => {
    // Converting the All Faculty data into the format [{label: string, value: string, group: string}]
    const facultyList: string[] = [];
    const allFacultyIdNameData: idNameFacultyDataType[] = [];
    // console.log('All Faculty Data: ', allFacultyData);

    // Collect all unique faculty names
    allFacultyData?.forEach((facultyData) => {
      facultyData.faculty.forEach((data) => {
        allFacultyIdNameData.push({
          driveId: data.driveId,
          name: data.name,
        });
        if (!facultyList.includes(data.name)) {
          facultyList.push(data.name);
        }
      });
    });

    // Create the formatted data
    const formattedAllFacultyData: FormattedFacultyDataSelect[] =
      facultyList.map((facultyName) => {
        return {
          label: facultyName,
          value: facultyName,
        };
      });

    dispatch(setFacultyIdNameData(allFacultyIdNameData));
    dispatch(setFacultyData(allFacultyData));
    dispatch(setFacultyOption(formattedAllFacultyData));
  }, [dispatch, allFacultyData]);

    const { centersData } = useFetchCentersData(userData.user.view);
    console.log('Getting Data of All Centers - Filters: ', centersData);

    // For gettinng the data of the current selected center
    const filteredCenter = centersData?.filter((data) =>
      center.includes(data.center)
    );


    // For storing the data of the room select component
    const roomDataOneOption: CenterwiseRoomData[] = [];

    // For storing the data of the batch select component
    const batchSelectComponentData: BatchSelectComponentType[] = [];

    // For filtering the rooms of the current selected component
    filteredCenter?.forEach(({ center, rooms }) =>
      rooms?.forEach((room, index) => {
        if (room === '') return;
        roomDataOneOption.push({
          group: center,
          label: room,
          value: room,
          key: `${center}-${index}`,
        });
      })
    );

    // For filtering the batch data for the current selected center
    filteredCenter?.forEach(({ center, batches }) =>
      batches?.forEach(({ batchId, batchName }, index) => {
        if (!batchId || !batchName) return;
        batchSelectComponentData.push({
          group: center,
          label: batchName,
          value: batchId,
        });
      })
    );

    const centerDataMultipleOption = centersData?.map((data) => {
      return { label: data.center, value: data.center };
    });

  return (
    <div className={styles['filters-container']}>
      <div className={styles['filters-container-select']}>
        <MultipleOption data={centerDataMultipleOption ?? []} />
        <OneOption
          data={roomDataOneOption}
          label='Room'
          nothingFound='No room found with the search query'
          placeholder='Select the Room'
        />
        <OneOption
          data={fileTypeData}
          label='File Type'
          nothingFound='No File Type found with the search query'
          placeholder='Select the File Type'
        />
        <BatchIdFilter batchSelectComponentData={batchSelectComponentData} />
        <YoutubeIdFilter />
        <Date />
        <OnlineClients />
      </div>
      <div className={styles['filters-container-buttons']}>
        <Reset />
        <DownloadCSV />
      </div>
    </div>
  );
};

export default Filters;
