import React from "react";
import CenterRoom from "../center/CenterRoom";
import Date from "../date/Date";
import FileName from "../file/FileName";
import FileType from "../file/FileType";
import Status from "../status/Status";
import LateBy from "../status/LateBy";
import ThumbNail from "../thumbnail/ThumbNail";
import Actions from "../action/Actions";

const DataModal = ({ document }) => {
  return (
    <div>
      <CenterRoom document={document} />
      <br />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <FileName document={document} />
        <FileType document={document} />
      </div>
      <br />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <Date document={document} />
        <Status document={document} />
      </div>
      <br />
      <LateBy document={document} />
      <br />
      <ThumbNail document={document} />
      <br />
      <Actions document={document} />
    </div>
  );
};

export default DataModal;
